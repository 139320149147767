import React, { FC } from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from '@reach/router'
import { IUser } from '../../interfaces/user.interface'

interface IProps extends RouteComponentProps {
    readonly user: Partial<IUser>
}

const StyledImg = styled.img`
    max-width: 30%;
`

const Home: FC<IProps> = ({ user }) => (
    <p>
        <StyledImg src={user.picture} alt='user pic' />
        Hi, {user.name ? user.name : 'friend'}!
    </p>
)

export default Home
