import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { Router } from '@reach/router'
import { Link } from 'gatsby'
import React, { FC } from 'react'
import styled from 'styled-components'
import Home from '../components/Account'
import Layout from '../components/common/Layout'

const StyledPageContainer = styled.div(({ theme }) => ({
    padding: `0 ${theme.spacing(4)}`,
}))

const Account: FC = () => {
    const { user, isLoading, logout } = useAuth0()

    if (!user) {
        return <p>No user</p>
    }

    if (isLoading) {
        return <p>Loading...</p>
    }

    return (
        <Layout isLogoShown={true}>
            <StyledPageContainer>
                <nav>
                    <Link to='/account'>Main page</Link>{' '}
                    <a
                        href='#logout'
                        onClick={(e) => {
                            e.preventDefault()
                            logout()
                        }}
                    >
                        Log Out
                    </a>
                </nav>
                <Router>
                    <Home path='/account' user={user} />
                </Router>
            </StyledPageContainer>
        </Layout>
    )
}

export default withAuthenticationRequired(Account)
